<template>
  <div class="py-[32px] px-[42px] flex flex-row items-start gap-x-6">
    <!-------------------------------------------------- LEFT BLOCK -------------------------------------------------->
    <div class="w-[400px] min-w-[400px] max-w-[400px] bg-white rounded-lg">
      <!-- LIST OF USERS -->
      <conversation-item
        :on-conversation-clicked="onConversationClicked"
        :get-other-user="getOtherUser"
        :set-current-conversation="setCurrentConversation"
        :user-set="currentConversation"
        :chat-by.sync="typeOfChat"
      ></conversation-item>
    </div>
    <!-------------------------------------------------- RIGHT BLOCK -------------------------------------------------->
    <div
      class="flex justify-center items-center w-full bg-white rounded-lg min-h-300"
      v-if="hasNoConversation"
    >
      <h1 class="font-EffraR text-gray-500">
        Rechercher un patient pour initier une conversation...
      </h1>
    </div>
    <messages
      v-if="currentConversation"
      :current-conversation="currentConversation"
      :on-video-call-clicked="onVideoCallClicked"
    ></messages>
  </div>
</template>

<script>
import { find } from "lodash";
import { EventBus } from "@/event-bus";
const ConversationItem = () => import("./components/conversation-item");
const Messages = () => import("./components/messages");
const videoItem = () => import("@/views/dashboard/chat/VideoItem");
const loader = () => import("@/views/global-components/loader");

export default {
  data() {
    return {
      loader: true,
      messages: [],
      currentConversation: null,
      twilioAccessToken: "",
      isVideoCallActive: false,
      showVideoCall: false,
      showUserInfo: false,
      hasNoConversation: true,
      currentTab: "PATIENT" // DOCTOR, PATIENT
    };
  },
  components: {
    ConversationItem,
    videoItem,
    Messages,
    loader
  },
  mounted() {
    this.$store.dispatch("user/GET_TWILIO_TOKEN", this.onTwilioToken);

    EventBus.$on("refreshMessages", params => {
      this.onConversationClicked(params);
    });

    EventBus.$on("collectionVideoCall", data => {
      this.showVideoCall = false;
    });

    EventBus.$on("showUserBarChat", () => {
      this.showUserInfo = !this.showUserInfo;
    });
  },
  computed: {
    typeOfChat: {
      get() {
        return this.currentTab;
      },
      set(value) {
        this.currentTab = value;
      }
    }
  },
  methods: {
    setCurrentConversation(conversation) {
      this.currentConversation = conversation;
      this.onConversationClicked(conversation);
    },
    onVideoCallClicked() {
      EventBus.$emit("collectionVideoCall", {
        token: this.twilioAccessToken,
        conversationId: this.currentConversation._id,
        onVideoCallClicked: this.endCall
      });
    },
    onConversationClicked(conversation) {
      this.currentConversation = conversation;

      this.$nextTick(() => {
        this.hasNoConversation = false;
      });
    },
    onData(messages) {
      this.messages = messages;
    },
    getOtherUser(conversation) {
      return find(conversation.members, m => {
        return m._id !== JSON.parse(localStorage.getItem("userInfo"))._id;
      });
    },
    onTwilioToken(data) {
      if (data.ok) {
        const {
          data: { token: accessToken }
        } = data;
        this.twilioAccessToken = accessToken;

        this.loader = false;
      }
    },
    // Change Tab p = patients & d = doctors
    changeTab(type) {
      if (type === "p") {
        // Switch Chat to patients
        this.typeOfChat = "PATIENT";
        return;
      }

      if (type === "d") {
        // Switch Chat to doctors
        this.typeOfChat = "DOCTOR";

        return;
      }
    },
    // Call Exit
    endCall() {}
  }
};
</script>
